import axios from "axios";
import { GlobalData } from "./component/global";


let base_url = "https://prettysquad.co.id/api";

export const fetchHome = (success, error) =>{

  axios.get(base_url + `/homepage`).then((response)=>{
    if(response.data.result === 'error'){
      error(response.data);
    }else{
      success(response.data);
    }
  }).catch((e) => {

  })
}

export const fetchCategory = (success, error) =>{
  axios.post(base_url + '/category').then((data)=>{
    success(data.data);
  });
}

export const fetchLogin = (request, success, error) =>{
  axios.post(base_url + '/login', request).then((data)=>{
    success(data.data);
  });
}

export const fetchProfile = (success, error) => {

  // todo request body user_id & operator_id at the end will be removed
  axios.post(base_url + '/profilepage', null, 
  {
    headers: {
      'Content-Type': 'application/json',
      'ck-pretty': localStorage.getItem("sessid"),
    }
  }
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchForum = (request, success, error) =>{

  axios.post(base_url + '/forum/', request).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchForumDetail = (id, success, error) =>{

  axios.get(base_url + '/detail/' + id,
  {
    headers: {
      'Content-Type': 'application/json',
      'ck-pretty': localStorage.getItem("sessid"),
    }
  }  
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}


export const fetchForumTrending = (success, error) =>{

  axios.get(base_url + '/trending/3', 
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchForumTags = (success, error) =>{

  axios.get(base_url + '/tags', 
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchForumCreate = (request,success, error) =>{

  axios.post(base_url + '/cforum', request,
  {
    headers: {
      'Content-Type': 'application/json',
      'ck-pretty': localStorage.getItem("sessid"),
    }
  } 
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchForumReply = (request,success, error) =>{

  axios.post(base_url + '/reply', request,
  {
    headers: {
      'Content-Type': 'application/json',
      'ck-pretty': localStorage.getItem("sessid"),
    }
  } 
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchArticle = (request, success, error) =>{
  axios.post(base_url + '/articlepage', request).then((data)=>{
    success(data.data);
  });
}

export const fetchArticleDetail = (request, success, error) =>{

  // todo request body user_id & operator_id at the end will be removed
  axios.post(base_url + '/artdetail', request, 
  {
    headers: {
      'Content-Type': 'application/json',
      'ck-pretty': localStorage.getItem("sessid"),
    }
  }
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchVideoCategory = (request, success, error) =>{

  // todo request body user_id & operator_id at the end will be removed
  axios.post(base_url + '/vcatpage', 
  request, 
  {
    headers: {
      'Content-Type': 'application/json',
      'ck-pretty': localStorage.getItem("sessid"),
    }
  }
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchVideoSpesial = (request, success, error) =>{

  // todo request body user_id & operator_id at the end will be removed
  axios.post(base_url + '/vcinde', 
  null, 
  {
    headers: {
      'Content-Type': 'application/json',
      'ck-pretty': localStorage.getItem("sessid"),
    }
  }
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchVideoTime = (request, success, error) =>{

  // todo request body user_id & operator_id at the end will be removed
  axios.post(base_url + '/vtime', 
  request, 
  {
    headers: {
      'Content-Type': 'application/json',
      'ck-pretty': localStorage.getItem("sessid"),
    }
  }
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchVideoDetail = (request, success, error) =>{

  // todo request body user_id & operator_id at the end will be removed
  axios.post(base_url + '/vdetailpage', 
  request, 
  {
    headers: {
      'Content-Type': 'application/json',
      'ck-pretty': localStorage.getItem("sessid"),
    }
  }
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchNotif = (success, error) =>{

  axios.post(base_url + '/notif',null,
  {
    headers: {
      'Content-Type': 'application/json',
      'ck-pretty': localStorage.getItem("sessid"),
    }
  }
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{
    console.log(e);
  });
  
}

export const fetchNotifDelete = (request, success, error) =>{

  axios.post(base_url + '/ndelete',
  request,
  {
    headers: {
      'Content-Type': 'application/json',
      'ck-pretty': localStorage.getItem("sessid"),
    }
  }
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchLike = (request, success, error) =>{

  axios.post(base_url + '/like',
  request,
  {
    headers: {
      'Content-Type': 'application/json',
      'ck-pretty': localStorage.getItem("sessid"),
    }
  }
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchVideoLike = (request, success, error) =>{

  axios.post(base_url + '/vlike',
  request,
  {
    headers: {
      'Content-Type': 'application/json',
      'ck-pretty': localStorage.getItem("sessid"),
    }
  }
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchQuest = (success, error) =>{

  axios.post(base_url + '/quest', null,
  {
    headers: {
      'Content-Type': 'application/json',
      'ck-pretty': localStorage.getItem("sessid"),
    }
  }
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchSubmitAnswer = (request, success, error) =>{

  axios.post(base_url + '/answer', request,
  {
    headers: {
      'Content-Type': 'application/json',
      'ck-pretty': localStorage.getItem("sessid"),
    }
  }
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchSuggestion = (request, success, error) =>{

  axios.post(base_url + '/suggestion', request,
  {
    headers: {
      'Content-Type': 'application/json',
      'ck-pretty': localStorage.getItem("sessid"),
    }
  }
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchMorePage = (request, success, error) =>{

  axios.post(base_url + '/more/article', request
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchMoreOtherPage = (request, success, error) =>{

  axios.post(base_url + '/more/otherarticle', request
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchMoreVideo = (request, success, error) =>{

  axios.post(base_url + '/vcatmore', request
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchChangeName = (request, success, error) =>{

  axios.post(base_url + '/name', request,
  {
    headers: {
      'Content-Type': 'application/json',
      'ck-pretty': localStorage.getItem("sessid"),
    }
  }
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchRedeem = (request, success, error) =>{

  axios.post(base_url + '/redeem', request,
  {
    headers: {
      'Content-Type': 'application/json',
      'ck-pretty': localStorage.getItem("sessid"),
    }
  }
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchLuckyWheel = (success, error) => {

  const param = {
    operator : `${GlobalData.operator}`
  }
  // todo request body user_id & operator_id at the end will be removed
  axios.post(base_url + `/spin`, param, 
  {
    headers: {
      'Content-Type': 'application/json',
      'ck-pretty': localStorage.getItem("sessid"),
    }
  }
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchWinner = (request, success, error) => {

  // todo request body user_id & operator_id at the end will be removed
  axios.post(base_url + '/winner', request
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}


export const fetchReadNotif = (request, success, error) => {

  axios.post(base_url + '/notif/read', request, 
  {
    headers: {
      'Content-Type': 'application/json',
      'ck-pretty': localStorage.getItem("sessid"),
    }
  }
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}

export const fetchClearAllNotif = (request, success, error) => {


  axios.post(base_url + '/notif/clear', null, 
  {
    headers: {
      'Content-Type': 'application/json',
      'ck-pretty': localStorage.getItem("sessid"),
    }
  }
  ).then((data)=>{
    success(data.data);
  }).catch((e)=>{

  });
  
}
