import React, {useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react'
import SvgArrow from '../component/svg-arrow'
import PaymentTelco from '../component/payment-telco'
import PaymentEwallet from '../component/payment-ewallet'
import Isat from './payment/isat'
import Hti from './payment/hti'
import Tsel from './payment/tsel'
import {motion, AnimatePresence} from "framer-motion";
import { fetchHome, fetchLogin } from '../api_fetch'
import { useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {useNavigate } from 'react-router-dom'
import { AVATAR, ISLOGIN } from '../App'
import { useHookstate } from '@hookstate/core'
import Swal from 'sweetalert2'
import { isMobile } from 'react-device-detect'
import { GlobalData } from './global'

const PopupLogin = forwardRef((props, ref) => {

  const navigate = useNavigate();
  const alreadyLogin = useHookstate(ISLOGIN);

  const [msisdn, setMsisdn] = useState("")
  const [opId, setOperator] = useState(1)

  const [result, setResult] = useState()


  useImperativeHandle(ref, () => ({
    handlePopupLogin
  }));
  

  const [isOpenPayment, setOpenPayment] = useState(false)
  const [isOpenPaymentDsk, setOpenPaymentDsk] = useState(false)
  const [isPopupLogin, setPopupLogin] = useState(false)
  let body = document.body

  const clickOpenPayment = () => {
    setOpenPayment(!isOpenPayment)
  }
  const clickOpenPaymentDsk = () => {
    setOpenPaymentDsk(!isOpenPaymentDsk)
  }
  const handlePopupLogin = () => {
    setPopupLogin(!isPopupLogin)
    // props.togglePopupLogin()
    if(isPopupLogin === false){
      body.classList.add("overflow-hidden")
    }else{
      body.classList.remove("overflow-hidden")
    }
  }

  const popup = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
    exit: { opacity: 0, transition: { duration: .25} }
  }
  const popupbackdrop = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
    exit: { opacity: 0, transition: { duration: .25} }
  }
  const popupcontent = {
    hidden: { scale: 0 },
    show: { scale: 1, transition: { type: 'spring', damping: 20, stiffness: 500 } },
    exit: { scale: 0, transition: { duration: .15} }
  }

  const clickPopup = () => {
    handlePopupLogin()
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const phone = event.target.msisdn.value

    let data = ""

    if(phone.trim().length !== 0){
      data = {
        msisdn: phone
      }

      fetchLogin(data, (result)=>{

        if(result.hasOwnProperty('rc')){
          if(result.rc === 99){
            // Swal.fire({
            //   position: 'center',
            //   icon: 'error',
            //   title: 'Pastikan Nomor anda Terdaftar',
            //   showConfirmButton: false,
            //   timer: 1500
            // })

            if(isMobile)
              setOpenPayment(true);

          }else{
            ISLOGIN.set(result.sessid)
    
            setResult(result)
  
            localStorage.setItem("sessid",result.sessid)
            localStorage.setItem("phone",phone)

            
            setPopupLogin(false)
    
            // use this instead navigate fix freeze ui problems after login
            window.location.replace('/'+GlobalData.operator+'/profile'); 
           
          }
        }
        
      });
     
    }    
  }

  const googleOk = (e) => {
    
    if(e && e.credential){

      let data = {
        token: e.credential, 
        clientId: e.clientId
      }
  
      fetchLogin(data, (result)=>{
        setResult(result)
        localStorage.setItem("sessid",result.sessid)
        ISLOGIN.set(result.sessid)
  
        setPopupLogin(false)
        
        // use this instead navigate fix freeze ui problems after login
        if(GlobalData.operator !== 'all'){
          window.location.replace('/'+GlobalData.operator+'profile');
        }else{
         window.location.replace('/profile');
        } 
      });
    }
    }
    
  const googleError = (e) => {
    console.log(e);
  }

  const  checkGlobaldata=(dd)=>{
    console.log(GlobalData.operator +'  '+dd);
    let data = <><Isat/><Hti/><Tsel/></>;
    if(GlobalData.operator == 'isat'){
      data = <><Isat/></>;
    }else if(GlobalData.operator ==='hti'){ 
      data = <><Hti/></>;
    }else if(GlobalData.operator='tsel'){
      data = <Tsel/>;
    }
    return data;
  }

  return (
    <>
    {/* <button className="relative z-50" onClick={clickPopup}>press</button> */}
    <AnimatePresence>
    {isPopupLogin && (<motion.div className="popup fixed flex items-center justify-center flex-col z-40 top-0 bottom-0 left-0 right-0 overflow-hidden"
      variants={popup}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      {isPopupLogin && (<motion.div className="popup-backdrop absolute top-0 bottom-0 left-0 right-0" onClick={handlePopupLogin}
        variants={popupbackdrop}
        initial="hidden"
        animate="show"
        exit="exit"
      ></motion.div>)}
      {isPopupLogin && (<motion.div className="popup-content relative mx-auto"
        variants={popupcontent}
        initial="hidden"
        animate="show"
        exit="exit"
      >
        <button className="btn-close-login absolute right-0"><img src="https://s.tykcdn.com/assets/pretty_squad/icon/close_btn_pink.svg" onClick={handlePopupLogin} alt="" /></button>
        <div className="h-full overflow-auto">
          <div className="lg:flex items-center">
            <div className="popup-top px-4 lg:px-8 py-7">
              <img src="https://s.tykcdn.com/assets/pretty_squad/icon/pretty_squad_logo.svg" className="block mx-auto" alt="" />
              <div className={"popup-top-inner " + (isOpenPayment === true ? "hidden" : "")}>
                <h5 className="font-medium text-center mt-5 lg:mt-7">Welcome back, <b>Pretty!</b></h5>
                <div className="popup-top-btn flex justify-center flex-col lg:flex-row mt-3 lg:mt-5">
                  {/* <button className="fb flex items-center justify-center w-full"><img src="https://s.tykcdn.com/assets/pretty_squad/icon/fb.svg" alt="" />Facebook</button> */}
                  {/* <button onClick={() => login()} className="go flex items-center justify-center w-full"><img src="https://s.tykcdn.com/assets/pretty_squad/icon/google.svg" alt="" />Google</button> */}
                
                  <GoogleOAuthProvider clientId="481150196352-8509l8gc17vnt28shifb0ma74lijgua7.apps.googleusercontent.com">
                    <GoogleLogin onSuccess={googleOk} onError={googleError}/>
                  </GoogleOAuthProvider>
                </div>
                <h6 className="font-medium text-center my-1">or</h6>
                <form onSubmit={handleSubmit}>
                <input name='msisdn' type="tel" pattern="[0-9]*" className="w-full px-6" placeholder="No.HP" onWheel={event => { event.preventDefault(); }}/>
                <button type='submit' className="btn-popup-login w-full font-semibold mt-4 lg:mt-12 lg:mx-auto block">Log In</button>
                </form>
              </div>
            </div>
            <div className="popup-btm lg:pr-4 lg:py-8">
              <div className="popup-btm-inner px-4 pb-7 lg:pb-0">
                <div className="popup-btm-content-wrapper lg:px-4 lg:py-5">
                  <div className="hidden lg:block">
                    <div className="payment-option-sub font-semibold mb-4 text-center">Gabung layanan premium disini!</div>
                    <div className={"payment-option-lg flex justify-center gap-4 mx-auto " + (isOpenPaymentDsk === true ? "hidden" : "")}>
                      {checkGlobaldata('pertama')}
                    </div>
                    <div className={"payment-option-lg payment-option-lg-btm grid grid-cols-4 gap-x-3 gap-y-8 mx-auto " + (isOpenPaymentDsk === true ? "" : "hidden")}>
                       {checkGlobaldata('kedua')}
                      
                      {/* <Isat/> */}
                      {/* <Xl/> */}
                      {/* <Hti/> */}
                      {/* <Tsel/> */}
                      {/* <Sf/>
                      <Gopay/>
                      <Spay/>
                      <Ovo/> */}
                    </div>
                    {/* <button className={"btn-payment-option w-full font-semibold mt-8 mx-auto flex items-center justify-center " + (isOpenPaymentDsk === true ? "hidden" : "")} onClick={clickOpenPaymentDsk}>Pilih Payment Lainnya<SvgArrow/></button> */}
                    <button className={"btn-payment-option btn-payment-option-back w-full font-semibold mt-8 mx-auto flex items-center justify-center " + (isOpenPaymentDsk === true ? "" : "hidden")} onClick={clickOpenPaymentDsk}><SvgArrow/>Kembali</button>
                  </div>
                  <div className={"popup-btm-content profile-join-detail lg:flex items-center px-5 py-4 " + (isOpenPaymentDsk === true ? "hidden" : "")}>
                    <div className={"payment-option " + (isOpenPayment === true ? "" : "hidden")}>
                      <div className="payment-option-sub font-semibold mb-1">Gabung layanan premium disini!</div>
                      <PaymentTelco/>
                      {/* <PaymentEwallet/> */}
                      <hr/>
                    </div>
                    <div className="profile-join-detail-sub font-semibold mb-2 lg:mb-3">Dapatkan<br className="hidden lg:block"/> keuntungan :</div>
                    <ul className="lg:ml-5">
                      <li>Nonton premium content sepuasnya</li>
                      <li>Menangkan daily prize!</li>
                      <li>Main lucky draw berhadiah!</li>
                      <li>Kumpulkan poin dan dapatkan grand prize!</li>
                    </ul>
                    <button className={"btn-popup-pay w-full mt-5 mx-auto block lg:hidden " + (isOpenPayment === true ? "hidden" : "")} onClick={clickOpenPayment}>Pilih Pembayaran</button>
                    <button className={"btn-popup-pay btn-popup-back w-full mt-5 mx-auto flex lg:hidden items-center justify-center " + (isOpenPayment === true ? "" : "hidden")} onClick={clickOpenPayment}><SvgArrow/>Kembali</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>)}
    </motion.div>)}
    </AnimatePresence>
    </>
  )
})

export default PopupLogin