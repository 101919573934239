import React, {useState, useEffect, useRef} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import { fetchNotif, fetchReadNotif, fetchSuggestion, fetchNotifDelete, fetchClearAllNotif } from '../api_fetch';
import PopupLogin from '../component/popup-login'
import { useHookstate } from '@hookstate/core';
import { AVATAR, ISLOGIN, PROFILE } from '../App';
import {useMediaQuery} from 'react-responsive'
import { useDebounce } from 'use-debounce';
import { Helmet } from 'react-helmet';
import { GlobalData } from './global';


const Header = (props) => {  
  
  const childRef = useRef();

  const navigate = useNavigate()

  const handleClickPopup = () => {
    childRef.current.handlePopupLogin()
  }
  
  const [isNavActive, setNavActive] = useState(false)
  const [isBigNav, setBigNav] = useState(false)
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isSearchOpen, setSearchOpen] = useState(false)
  const [isNotifOpen, setNotifOpen] = useState(false)

  const [mynotif, setNotif] = useState();
  const [newnotif, setNewNotif] = useState(false);

  const myAvatar = useHookstate(AVATAR);  
  const alreadyLogin = useHookstate(ISLOGIN);

  const [suggest, setSuggest] = useState();

  const [length, setLength] = useState(0)
  const isDesktop = useMediaQuery({query: '(max-width: 768px)'})

  const [text, setText] = useState('');
  const [value] = useDebounce(text, 500);

  
  useEffect(() => {
    init()
  },[])

  useEffect(()=>{
   
    setSuggest();
    setLength(value.length);
    handleChangeSearch(value);
  },[value])

  const init = () =>{


    const scrolls = () =>{
      if(window.scrollY > 10){
        setNavActive(true)
      }else{
        setNavActive(false)
        setBigNav(false)
      }
      
    };

    window.addEventListener('scroll', scrolls);

    return () => {
      window.removeEventListener('scroll', scrolls);
    };
  }

  const navBig = () => {
    openSearch()
    if(isNavActive === true){
      setNavActive(false)
      setBigNav(true)
    }else{
      setNavActive(true)
      setBigNav(false)
    }
  }

  const openMenu = () => {
    if(isMenuOpen === false){
      setMenuOpen(true)
      document.body.classList.add('overflow-y-hidden')
    }else{
      setMenuOpen(false)
      document.body.classList.remove('overflow-y-hidden')
    }
  }
  const openSearch = () => {

    if(isSearchOpen === false){
      setSearchOpen(true)
      setNotifOpen(false)

      if(isDesktop){
        document.body.classList.add('overflow-y-hidden')
      }


      let body = {
        word: ""
      }
  
      fetchSuggestion(body,(data)=>{
        setSuggest(data.articles.concat(data.videos));
      })
    }else{
      setSearchOpen(false)
    }
  }

  const closeSearch = () => {
    setSearchOpen(false)
    if(isDesktop){
      document.body.classList.remove('overflow-y-hidden')
    }
  }



  const openNotif = () => {

    if(alreadyLogin.get()!==null){

      fetchNotif((data) => { 
        setNotif(data.notif)
      });

      if(isNotifOpen === false){
        setNotifOpen(true)
        setSearchOpen(false)

        if(isDesktop){
          setNavActive(false)
          setBigNav(true)
          document.body.classList.add('overflow-y-hidden')
        }
  
      }else{
        setNotifOpen(false)

        //todo read all notif

        if(isDesktop){
          setNavActive(true)
          setBigNav(false)
          document.body.classList.remove('overflow-y-hidden')
        }
  
      }
    }else{
      handleClickPopup()
    }
    
  }

  const readNotif = (id) => {

    let body = {
      notifId: id
    }

    fetchReadNotif(body,(data)=>{

    })
  }

  const handleChangeSearch = (searchStr) => {

      let body = {
        word: searchStr
      }
  
      fetchSuggestion(body,(data)=>{
        setSuggest(data.articles.concat(data.videos));
      })    
  }

  const clickMenu = (link) => {
    setMenuOpen(false)
    document.body.classList.remove('overflow-y-hidden')
    navigate(link);
  }

  const openProfile = () => {
    if(alreadyLogin.get()!==null){
      setSearchOpen(false)
      setNotifOpen(false)

      navigate('/profile');
    }else{
      handleClickPopup()
    }
  }

  const clearAllNotif = () =>{
    // todo call api clear all notif

    fetchClearAllNotif((data)=>{

      fetchNotif((dataResult) => {
        setNotif(dataResult.notif);
      });
    })
  }

  const handleNotifGoForum= (notifId, refId, commentId) => {
    readNotif(notifId);
    navigate("/forum-detail/" + refId + "/" + commentId);
  }

  const handleNotifGoArticle= (notifId, articleId) => {
    readNotif(notifId);
    navigate("/article-detail/"+articleId);
  }

  const handleNotifGoProfile= (notifId) => {
    readNotif(notifId);
    navigate('/profile');
  }

  const clearNotif = (id) => {

    let body = {
      notifId: id
    }

    fetchNotifDelete(body, (data) => {

      
      if(data.rc===0){
        fetchNotif((dataResult) => {
            setNotif(dataResult.notif);
        });
      }
    })
  }

  const selectSearch = (id, viewArticle) => {
    closeSearch();
    setText('');
    

    if(viewArticle === 1){
      navigate("/article-detail/"+id);
    }else{
      navigate("/video-play/"+id);
    }
    
  }
   

  return (
    <div>
      <Helmet>
      <meta name="google-adsense-account" content="ca-pub-7177847025492946"/>
      </Helmet>
    
    <header className={"fixed w-full top-0 z-30 " + (isNavActive || isBigNav ? 'shadow-md ' : '')}>
      <PopupLogin ref={childRef}/>
      <nav className={(isNavActive ? 'active ' : '') + (isBigNav ? 'bignav ' : '')}>
        <div className="nav-top absolute top-0 w-full"></div>
        <div className="nav-container container relative block md:flex items-center justify-between w-full">
          <div className="nav-wrapper relative z-10">
            <div className="btn-nav-wrapper btn-nav-wrapper-top flex items-center md:hidden">
              <button className="btn-nav" onClick={()=>openMenu()}><img src="https://s.tykcdn.com/assets/pretty_squad/icon/nav.svg" alt="" /></button>
              <button className="btn-input-mbl ml-2.5"></button>
            </div>
            <Link to="/" className="nav-logo"><img src="https://s.tykcdn.com/assets/pretty_squad/icon/pretty_squad_logo.svg" className="block mx-auto" alt="" /></Link>
            
          </div>
          <div className="nav-list hidden md:block text-center lg:ml-28 ml-10 mr-4">
            <Link to="/video-category?cid=5" className={props.type ==='video'?"font-semibold":""} >Videos</Link>
            <Link to="/article/1" className={props.type ==='article'?"font-semibold":""} >Articles</Link>
            <Link to="/forum-category/1" className={props.type ==='forum'?"font-semibold":""} >Forums</Link>
          </div>
          <div className="nav-form-wrapper relative w-full">
            <div className="nav-form flex items-center justify-end w-full">
            <div className="btn-nav-wrapper btn-nav-wrapper-btm flex items-center w-full md:hidden">
              <button className="btn-nav" onClick={openMenu}><img src="https://s.tykcdn.com/assets/pretty_squad/icon/nav.svg" alt="" /></button>
              <button className="btn-input-mbl ml-2.5" onClick={navBig}></button>
            </div>
            <div className="nav-form-inner flex items-center justify-end w-full">
              <div className={"nav-form-input relative w-full " + (isSearchOpen ? 'active' : '')}>
                <input value={text} type="text" className="pl-4 pr-9 w-full" placeholder="Search..." onChange={(e)=>setText(e.target.value)} onFocus={openSearch} />
                <img src="https://s.tykcdn.com/assets/pretty_squad/icon/search.svg" className="absolute" alt="" />
              </div>
              <button className={"ml-3 " + (isSearchOpen ? 'hidden' : '')} onClick={()=>openNotif()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="63" height="62" viewBox="0 0 63 62">
                  <g transform="translate(-1574 -50)">
                    <g transform="translate(310 10)">
                      <rect width="60" height="60" rx="30" transform="translate(1264 40)" fill="#ae74d3"/>
                      <g transform="translate(1284.792 59.167)">
                        <g transform="translate(-3.792 -2.167)">
                          <path d="M9.208,17.168c6.109,0,8.935-.784,9.208-3.929,0-3.144-1.97-2.941-1.97-6.8C16.446,3.428,13.591,0,9.208,0S1.97,3.428,1.97,6.441C1.97,10.3,0,10.1,0,13.239.274,16.4,3.1,17.168,9.208,17.168Z" transform="translate(3.792 2.167)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                          <path d="M5.275,0A3.429,3.429,0,0,1,0,0" transform="translate(10.313 22.595)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                        </g>
                      </g>
                    </g>
                    <g id="RedCircle" className={props.nNotif?"":"hidden"} transform="translate(1616 91)" fill="#ff5050" stroke="#fafafa" strokeWidth="2">
                      <circle cx="10.5" cy="10.5" r="10.5" stroke="none"/>
                      <circle cx="10.5" cy="10.5" r="9.5" fill="none"/>
                    </g>
                  </g>
                </svg>
              </button>
                <button onClick={()=>openProfile()}  className={"ml-3 " + (isSearchOpen ? 'md:block hidden' : '')}>
                <div className="user-header-img">         
                  {
                    myAvatar.get()?
                    <img src={myAvatar.get()} alt="User" />
                    :
                    <></>
                  }
                </div>  
                </button>
                </div>   
            </div>
            <div className={"search-notif-wrapper absolute " + (isSearchOpen ? '' : 'hidden')}>
              <div className="search-notif-head hidden md:flex items-center justify-between px-8 py-1">
                <h6>Search</h6>
                <button onClick={()=>closeSearch()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37">
                    <g transform="translate(-1564.953 -137.209)">
                      <circle cx="18.5" cy="18.5" r="18.5" transform="translate(1564.953 137.21)" fill="#ae74d3"/>
                      <path d="M-1115.6,2029.309v-.564a1.33,1.33,0,0,0,.039-.132,2.323,2.323,0,0,1,1.085-1.6,2.371,2.371,0,0,1,3,.4q3.008,3,6.011,6.011a1.323,1.323,0,0,1,.138.2h.087a1.332,1.332,0,0,1,.138-.2q2.968-2.972,5.939-5.94c.081-.081.162-.161.247-.237a2.379,2.379,0,0,1,3.721.779,2.37,2.37,0,0,1-.531,2.752q-3,3.007-6.012,6.01a1.437,1.437,0,0,1-.206.143c.094.1.143.153.194.2l5.954,5.954c.081.08.161.162.237.247a2.384,2.384,0,0,1-1.136,3.857c-.122.034-.245.061-.367.091h-.564a3.276,3.276,0,0,1-1.846-1.131c-1.87-1.89-3.758-3.764-5.636-5.646a.953.953,0,0,1-.122-.209l-.09.036a1.735,1.735,0,0,1-.136.174q-3,3-6,6a2.538,2.538,0,0,1-1.08.675c-.134.04-.271.069-.407.1h-.564a.555.555,0,0,0-.092-.035,2.334,2.334,0,0,1-1.885-1.617c-.051-.144-.08-.3-.119-.443v-.564a3.319,3.319,0,0,1,1.146-1.861c1.886-1.865,3.755-3.747,5.633-5.62a.954.954,0,0,1,.209-.122l-.036-.09a1.8,1.8,0,0,1-.174-.136q-3-3-6-5.995a2.531,2.531,0,0,1-.676-1.079C-1115.536,2029.582-1115.565,2029.445-1115.6,2029.309Z" transform="translate(2688.558 -1880.973)" fill="#fff"/>
                    </g>
                  </svg>
                </button>
              </div>
              <div className="search-notif-body">
                <div className="search-body-inner overflow-y-auto px-6 pb-6">
                  <div className="search-body-head sticky top-0 flex items-center justify-between py-3 md:pt-1 md:pb-0">
                    <h5 className="font-semibold">{length>0?'Search':'Suggestion'}</h5>
                    <button className="font-semibold md:opacity-0 opacity-1" onClick={()=>closeSearch()}>Cancel</button>
                  </div>
                  <div className="search-body-top">
                    <ul>

                      {suggest?.map((s,index) => (

                            <React.Fragment key={"s"+s.title}>
                            
                            {s.listArticle?.map((l)=>(
                              <li className="search-body-top-item" key={'l'+l.title}>
                                  
                                  <div className="pb-2" onClick={()=>selectSearch(l.article_id,1)}>
                                    <div className="mb-1"><label className={
                                      s.category_id===1?"purple":
                                      s.category_id===3?"orange":
                                      s.category_id===4?"green":
                                      "blue"}>{s.title}</label></div>
                                    <div className="sbti-text line-clamp-2">{l.title}</div>
                                  </div>
                                  
                              </li>
                            ))}
                             {s.listVideo?.map((k)=>(
                              <li className="search-body-top-item" key={'k'+k.title}>
                                  
                                    <div className="pb-2" onClick={()=>selectSearch(k.video_id,1)}>
                                      <div className="mb-1"><label className={
                                        s.category_id===1?"purple":
                                        s.category_id===3?"orange":
                                        s.category_id===4?"green":
                                        "blue"}>{s.title}</label></div>
                                      <div className="sbti-text line-clamp-2">{k.title}</div>
                                    </div>
                                  
                              </li>
                            ))}
                         
                            </React.Fragment>
                      ))}
                      
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className={"search-notif-wrapper absolute " + (isNotifOpen ? '' : 'hidden')}>
              <div className="search-notif-head hidden md:flex items-center justify-between px-8 py-1">
                <h6>Notification</h6>
                <button onClick={()=>openNotif()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37">
                    <g transform="translate(-1564.953 -137.209)">
                      <circle cx="18.5" cy="18.5" r="18.5" transform="translate(1564.953 137.21)" fill="#ae74d3"/>
                      <path d="M-1115.6,2029.309v-.564a1.33,1.33,0,0,0,.039-.132,2.323,2.323,0,0,1,1.085-1.6,2.371,2.371,0,0,1,3,.4q3.008,3,6.011,6.011a1.323,1.323,0,0,1,.138.2h.087a1.332,1.332,0,0,1,.138-.2q2.968-2.972,5.939-5.94c.081-.081.162-.161.247-.237a2.379,2.379,0,0,1,3.721.779,2.37,2.37,0,0,1-.531,2.752q-3,3.007-6.012,6.01a1.437,1.437,0,0,1-.206.143c.094.1.143.153.194.2l5.954,5.954c.081.08.161.162.237.247a2.384,2.384,0,0,1-1.136,3.857c-.122.034-.245.061-.367.091h-.564a3.276,3.276,0,0,1-1.846-1.131c-1.87-1.89-3.758-3.764-5.636-5.646a.953.953,0,0,1-.122-.209l-.09.036a1.735,1.735,0,0,1-.136.174q-3,3-6,6a2.538,2.538,0,0,1-1.08.675c-.134.04-.271.069-.407.1h-.564a.555.555,0,0,0-.092-.035,2.334,2.334,0,0,1-1.885-1.617c-.051-.144-.08-.3-.119-.443v-.564a3.319,3.319,0,0,1,1.146-1.861c1.886-1.865,3.755-3.747,5.633-5.62a.954.954,0,0,1,.209-.122l-.036-.09a1.8,1.8,0,0,1-.174-.136q-3-3-6-5.995a2.531,2.531,0,0,1-.676-1.079C-1115.536,2029.582-1115.565,2029.445-1115.6,2029.309Z" transform="translate(2688.558 -1880.973)" fill="#fff"/>
                    </g>
                  </svg>
                </button>
              </div>

              <div className="search-notif-body">
                <div className="search-notif-body-head sticky top-0 flex items-center justify-between px-6 py-3 md:pt-1 md:pb-0">
                  <h5 className="font-medium md:opacity-0 opacity-1">Notification</h5>
                  <button onClick={()=>clearAllNotif()} className="font-semibold hidden md:block">Clear All</button>
                  <button className="font-semibold block md:hidden" onClick={()=>openNotif()}>Cancel</button>
                </div>
                <div className="search-notif-body-body absolute overflow-y-auto w-full h-full">
                  <ul className="overflow-y-auto h-full px-6">
                    
                    {mynotif?.map((notif) => (
                      <div onClick={(notif.type===1 || notif.type===2)?()=>handleNotifGoForum(notif.notif_id,notif.ref_id,notif.comment_id):
                        ((notif.type===3 || notif.type===4)?()=>handleNotifGoProfile(notif.notif_id):"")}>
                        <li key={'nt'+notif.notif_id} className="notif-item relative flex items-center justify-between py-6" 
                        >
                        {
                          notif.read===0?<div className="new-notif absolute"></div> : ""
                        }
                        <div className="absolute"></div>
                        <span className="notif-item-left" >
                         
                          <div className="flex items-center justify-between" >
                            <span className="nil-left">
                              <div className='nil-left-img'>
                              <img src={notif.image_url} alt="" />
                              </div>
                            </span>
                            <span className="nil-right w-full px-5">
                              <div className="nilr-time mb-2 mb-2">{notif.dateStr}</div>
                              <div className="nilr-text line-clamp-2">{notif.actionStr}</div>
                            </span>
                          </div>
                        </span>
                        <span className="notif-item-right">
                          <button onClick={()=>clearNotif(notif.notif_id)} className="btn-clear-item"></button>
                        </span>
                      </li>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className={"mobile-menu fixed top-0 w-full h-full block md:hidden " + (isMenuOpen ? 'open' : '')}>
        <button className="btn-close absolute shadow-md" onClick={()=>openMenu()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 66 66">
            <g transform="translate(-1564.453 -137.209)">
              <circle cx="33" cy="33" r="33" transform="translate(1564.453 137.21)" fill="#f86bcd"/>
              <path d="M-1115.6,2031.328v-.992a2.417,2.417,0,0,0,.068-.232,4.085,4.085,0,0,1,1.908-2.814,4.171,4.171,0,0,1,5.276.7q5.29,5.28,10.57,10.57a2.348,2.348,0,0,1,.242.347h.153a2.348,2.348,0,0,1,.242-.347q5.219-5.226,10.444-10.446c.142-.142.285-.284.435-.416a4.184,4.184,0,0,1,6.543,1.369,4.168,4.168,0,0,1-.934,4.84q-5.282,5.288-10.572,10.568a2.545,2.545,0,0,1-.362.251c.166.174.251.269.341.359l10.471,10.47c.142.141.284.284.416.435a4.192,4.192,0,0,1-2,6.782c-.214.06-.431.107-.646.16h-.992a5.759,5.759,0,0,1-3.246-1.99c-3.29-3.324-6.608-6.619-9.911-9.93a1.677,1.677,0,0,1-.215-.367l-.159.063a3.122,3.122,0,0,1-.239.307q-5.272,5.276-10.545,10.549a4.466,4.466,0,0,1-1.9,1.187c-.235.07-.476.122-.715.181h-.992a.975.975,0,0,0-.161-.061,4.1,4.1,0,0,1-3.314-2.844c-.09-.252-.141-.519-.209-.78v-.992a5.834,5.834,0,0,1,2.015-3.272c3.316-3.28,6.6-6.589,9.905-9.883a1.688,1.688,0,0,1,.367-.215l-.063-.158a3.156,3.156,0,0,1-.307-.24q-5.275-5.27-10.549-10.542a4.456,4.456,0,0,1-1.188-1.9C-1115.488,2031.809-1115.539,2031.567-1115.6,2031.328Z" transform="translate(2695.009 -1874.553)" fill="#fff"/>
            </g>
          </svg>
        </button>
        <ul className="absolute abs-center w-full font-medium">
          <li><button onClick={()=>clickMenu('/video-category?cid=5')}><div className="active pl-14 pr-4 py-2">Videos</div></button></li>
          <li><button onClick={()=>clickMenu('/video-program')} ><div className="pl-14 pr-4 py-2">Program</div></button></li>
          <li><button onClick={()=>clickMenu('/article/1')} ><div className="pl-14 pr-4 py-2">Article</div></button></li>
          <li><button onClick={()=>clickMenu('/forum-category/1')} ><div className="pl-14 pr-4 py-2">Forums</div></button></li>
        </ul>
      </div>
    </header>
    </div>
  )
}

export default Header