import React, {useState,useRef, useEffect} from 'react'
import Header from '../component/header'
import FloatingBtn from '../component/floating-btn'
import Footer from '../component/footer'
import Breadcrumb from '../component/breadcrumb'
import ProfileImg from '../component/profile-img'
import SliderContent from '../component/slider-content'
import { fetchChangeName, fetchProfile, fetchRedeem, fetchRelogin, fetchNotif } from '../api_fetch'
import {motion, AnimatePresence} from "framer-motion";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Isat from '../component/payment/isat'
import { WhatsappShareButton } from 'react-share'

import Hti from '../component/payment/hti'
import { fetchLogin } from '../api_fetch'
import ReactGA from "react-ga4";
import { AVATAR, ISLOGIN, NAME } from '../App'
import { useHookstate } from '@hookstate/core'
import { GlobalData } from '../component/global'

import Tsel from '../component/payment/tsel'

const Profile = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const { msisdn } = useParams();

  const sessid = useHookstate(ISLOGIN);

  const [result,setResult] = useState();
  const [isLoading, setLoading] = useState(true);

  const [openTab, setOpenTab] = useState(1);
  const [openHstTab, setOpenHstTab] = useState(1);
  const [isPopupStatus, setPopupStatus] = useState(false);
  const [isPopupPoin, setPopupPoin] = useState(false);
  const [isPopupRedeemKuota, setPopupRedeemKuota] = useState(false);
  const [isPopupRedeemGopay, setPopupRedeemGopay] = useState(false);
  const [isPopupRedeemBeauty, setPopupRedeemBeauty] = useState(false);
  const [isPopupRedeemStatus, setPopupRedeemStatus] = useState(false);
  const [contvid, setVideoContinue] = useState();
  const [isBlur, setBlur] = useState(false);
  let body = document.body;
  const [expiredStr, setExpiredStr] = useState();
  const [isExpired, setIsExpired] = useState(true);
  const [redeemPrize, setRedeemPrize] = useState();
  const myAvatar = useHookstate(AVATAR);

  const [history, setHistory] = useState();

  let newName = "";
  const [pid,setPid]= useState(0);
  const [myPoint, setMyPoint] = useState(0);
  const [indexPrize,setIndexPrize]= useState(0);

  const [msgRedeem, setMsg] = useState();
  const [descRedeem, setDesc] = useState();

  const [newNotif, setNewNotif] = useState(false);

  if(sessid.get() === null){
    // window.location.replace('/');
  }  


  const ref = useRef(null);
  const[isEdit, setEdit] = useState(false)
  const[isCheckEdit, setCheckEdit] = useState(false);
  const[showPop, setPop] = useState(false)

  const CheckInput = event => {
    if(event.target.value.length === 0){
      setEdit(false)
      setCheckEdit(false)
    }else{
      setEdit(true)
      setCheckEdit(true)

      newName = event.target.value
    }

    
  }
  const ClickEdit = () => {
    if(isEdit == false || ref.current.blur()){
      setEdit(true)
      ref.current.focus()
    }else{
      setEdit(false)
      ref.current.blur()
      handleChangeName()
    }
  }

  const handlePopupStatus = () => {
    setPopupStatus(!isPopupStatus);
    // setBlur(!isBlur);

    if(isPopupStatus === false){
      body.classList.add("overflow-hidden")
    }else{
      body.classList.remove("overflow-hidden")
    }
  }

  const handlePopupPoin = () => {
    setPopupPoin(!isPopupPoin);

    if(isPopupPoin === false){
      body.classList.add("overflow-hidden")
    }else{
      body.classList.remove("overflow-hidden")
    }
  }

  const handlePopupRedeemKuota = (prizeId, index) => {

    setPid(prizeId)
    setIndexPrize(index)
    
    // CHECK IF NOT SUBCRIBE YET
    if(result.myprofile.subscribed===false){
      handlePopupStatus()
    }else{
      setPopupRedeemKuota(!isPopupRedeemKuota);
      // setBlur(!isBlur);
  
      if(isPopupRedeemKuota === false){
        body.classList.add("overflow-hidden")
      }else{
        body.classList.remove("overflow-hidden")
      }     
    }
  }

  const handlePopupRedeemGopay = (prizeId) => {

    setPid(prizeId)
    
    // CHECK IF NOT SUBCRIBE YET
    if(result.myprofile.subscribed===false){
      handlePopupStatus()
    }else{
      setPopupRedeemGopay(!isPopupRedeemGopay);
      // setBlur(!isBlur);

      if(isPopupRedeemGopay === false){
        body.classList.add("overflow-hidden")
      }else{
        body.classList.remove("overflow-hidden")
      }
    }
  }

  const handlePopupRedeemBeauty = (prizeId) => {
    setPid(prizeId)
    
    // CHECK IF NOT SUBCRIBE YET
    if(result.myprofile.subscribed===false){
      handlePopupStatus()
    }else{
      setPopupRedeemBeauty(!isPopupRedeemBeauty);
      // setBlur(!isBlur);

      if(isPopupRedeemBeauty === false){
        body.classList.add("overflow-hidden")
      }else{
        body.classList.remove("overflow-hidden")
      }
    }
  }

  const handlePopupRedeemStatus = (prizeId) => {

    setPopupRedeemStatus(!isPopupRedeemStatus);
    setPopupRedeemKuota(false);
    setPopupRedeemGopay(false);
    setPopupRedeemBeauty(false);
    // setBlur(!isBlur);

    if(isPopupRedeemStatus === false){
      body.classList.add("overflow-hidden")
    }else{
      body.classList.remove("overflow-hidden")
    }

    // REDEEM PROCESS HERE
    if(prizeId>0){
      handleRedeem(prizeId)
    }
  }

  const handleRedeem = (pid) => {

    let body = {
      prize_id:pid
    }

    fetchRedeem(body, (data)=>{

      if(data.hasOwnProperty('rc')){
        let rc = data.rc

        if(rc===0){
          setMyPoint(data.point)
          setDesc('Sisa Point kamu ' + data.point)
        }else if(rc=== -5 || rc === -6){
          setDesc('Kamu bisa coba lagi minggu depan atau Redeem hadiah yang lain ya!')
        }else if(rc=== -3){
          setDesc('Ayo terus mainan kuis harian untuk menambah point kamu!')
        }else if(rc=== -2){
          setDesc('Ayo berlangganan dan tukarkan hadiah setiap minggunya!')
        }

        setMsg(data.msg)
      }
    })
  }

  const popup = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
    exit: { opacity: 0, transition: { duration: .25} }
  }
  const popupbackdrop = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
    exit: { opacity: 0, transition: { duration: .25} }
  }
  const popupcontent = {
    hidden: { scale: 0 },
    show: { scale: 1, transition: { type: 'spring', damping: 20, stiffness: 500 } },
    exit: { scale: 0, transition: { duration: .15} }
  }

  const init = () => {

    // AUTOLOGIN FLOW
    if(msisdn){

      let data = {
        msisdn: msisdn
      }
  
      fetchLogin(data, (result)=>{
  
        if(result.hasOwnProperty('rc')){
          if(result.rc === 99){
            navigate('/', {
              state: {
                needLogin: true
              }
            });
          }else{
            ISLOGIN.set(result.sessid)    
            AVATAR.set(result.image_url)
    
            setResult(result)
  
            localStorage.setItem("sessid",result.sessid)
            localStorage.setItem("phone",msisdn)
            // myprofile()

            navigate('/', {
              state: {
                needLogin: false
              }
            });
          }
        }
        
      });

      
    }else{
      myprofile()
    }

    checkNotif();

   
  } // end init()

  const checkNotif = () => {

    fetchNotif((data)=>{
      setNewNotif(data.newNotif);
    })
  }

  const myprofile = () => {
      
      fetchProfile((data)=>{
  
        if(data.hasOwnProperty('rc')){
          if(data.rc===99){
            navigate('/', {
              state: {
                needLogin: true
              }
            });
          }
        }else{
          setResult(data)
          setHistory(data.history)
          setRedeemPrize(data.redeem_list)
          setMyPoint(data.myprofile.nb_point)
          localStorage.setItem('myname', data.myprofile.name)
          console.log(data.myprofile)
          AVATAR.set(data.myprofile.image_url)
    
          if(data.myprofile.operator_id==1){
            localStorage.setItem("operator",'isat');
          }else if(data.myprofile.operator_id==2){
            localStorage.setItem("operator",'tsel');
          }else if(data.myprofile.operator_id==3){
            localStorage.setItem("operator",'hti');
          }else{
            localStorage.setItem("operator",'all');
          }

          
      

          const newDate = new Date(data.myprofile.exp_date);
          const current = new Date().getTime();
    
          setExpiredStr(newDate.toLocaleDateString('en-GB') );
    
          setVideoContinue(data.continue_list)
    
          if(data.myprofile.exp_date<current){
            setIsExpired(true)
          }else{
            setIsExpired(false)
          }
          
          setLoading(false);
        }
  
      });
  
      try{

        ReactGA.send({hitType:"pageview", page: "" + localStorage.getItem("operator") + window.location.pathname});
   
        if(location.state.needSubs){
          handlePopupStatus()
  
          // prevent popup occur everytime reload page
          window.history.replaceState({}, document.title) 
        }
      }catch(e){
  
      }
  }

  const handleLogout = () => {
    localStorage.clear()
    AVATAR.set(null)
    ISLOGIN.set(null)
    navigate('/')
  }

  const needSubscribe = () => {
    handlePopupStatus()
  }


  const handleChangeName = () =>{

    if(newName.length>0){
      let body = {
        name:newName
      }
  
      fetchChangeName(body, (data)=>{
        localStorage.setItem('myname', newName)
        NAME.set(newName)
      })
    }
    
  }

  const disiniClick = () =>{
    setPopupPoin(!isPopupPoin);
    setOpenTab(1);

    if(isPopupPoin === false){
      body.classList.add("overflow-hidden")
    }else{
      body.classList.remove("overflow-hidden")
    }
  }

  useEffect(() => {

    if(ISLOGIN){
      init()
    }else{
      navigate('/')
    }

  }, []);
  
  if(isLoading)
    return (
      <div className="relative">
        <div className="top absolute top-0 w-full"></div>
        <div className="wrapper relative z-10">
          <Header/>
          <FloatingBtn/>
        </div>
      </div>
    )

  
  return (
    
    <div className="relative">
      <div className="top absolute top-0 w-full"></div>
      <div className="wrapper relative z-10">
        <Header nNotif={newNotif}/>
        <FloatingBtn/>
        <main>
          <div className="content">
            <section className="container pt-4 pb-16 lg:pt-12">
              <Breadcrumb/>
              <div>
                <h4 className="font-semibold mb-4 sm:hidden">Profile</h4>
                <div className="profile-wrapper flex flex-col lg:flex-row">
                  <div className="profile-top sm:flex w-full lg:w-1/2 gap-14 px-4 sm:px-14 py-5 sm:py-8">
                    <div className="w-full md:w-1/3">
                      <ProfileImg img={result.myprofile.image_url?result.myprofile.image_url:"https://s.tykcdn.com/assets/pretty_squad/icon/user_def.svg"}/>
                    </div>
                    <div className="w-full md:w-2/3">
                      <div className="profile-user-display">
                        <label className="font-semibold">Nama</label>
                        <div className="profile-user-input relative">
                          <input type="text" onChange={CheckInput} ref={ref} className={"profile-user-display-in p-0 pr-20 " + (isEdit ? "pl-2" : "pl-0 pointer-events-none") + (isCheckEdit && isEdit === true ? "" : " pudi-empty")} placeholder={result.myprofile.name? result.myprofile.name: "N/A"}  />
                          <button className="profile-user-input-edit btn-abs absolute right-0 font-semibold" onClick={ClickEdit}>{isEdit === true ? "Submit" : "Edit"}</button>
                        </div>
                      </div>
                      <div className="profile-user-display">
                        <label className="font-semibold">No. Handphone</label>
                        <div className="profile-user-display-in p-0">{result.myprofile.msisdn}</div>
                      </div>
                      <div className="profile-user-display">
                        <label className="font-semibold">Email</label>
                        <div className="profile-user-display-in p-0">{result.myprofile.email}</div>
                      </div>
                      <div className="profile-user-display">
                        <label className="font-semibold">Refferal code</label>
                        <div className="profile-user-referral flex items-center justify-between mt-1.5">
                          <span className="profile-user-referral-left h-full flex items-center px-5">{result.myprofile.referral_code}</span>
                          <span className="profile-user-referral-right h-full"><WhatsappShareButton title={"Hi Pretty! Yuk glowing bareng aku di Pretty Squad! Masukan ref code " + result.myprofile.referral_code + " untuk hadiah menariknya di "} url="https://prettysquad.co.id"  className="btn-profile-referral grid place-items-center w-full h-full"><img src="https://s.tykcdn.com/assets/pretty_squad/icon/share_btn_w.svg" alt="" /></WhatsappShareButton></span>
                        </div>
                      </div>
                      <div className="mt-6">
                        <button className="btn-status p-2 w-full lg:mx-0 mx-auto flex items-center justify-center" onClick={handlePopupStatus}>Status Berlangganan Premium <div className="chev-r ml-2"></div></button>
                      </div>
                      <div className="mt-9">
                        <button className="btn-logout lg:mx-0 mx-auto flex items-center justify-center" onClick={handleLogout}>Logout</button>
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 px-0 pb-0 mt-3 sm:mt-0">
                    <div className="profile-tab relative">
                      <div className="profile-tab-head relative flex items-center justify-start sm:justify-center px-4">
                        <h6>Total Point Kamu</h6>
                        <span className="profile-head-coin flex items-center px-2 py-0 ml-3"><img src="https://s.tykcdn.com/assets/pretty_squad/luckywheel/currency.svg" alt="Coin" />{myPoint}</span>
                        <button className="btn-info-point absolute" onClick={handlePopupPoin}><img src="https://s.tykcdn.com/assets/pretty_squad/luckywheel/info.svg" alt="Info" /></button>
                      </div>
                      <div className="profile-tab-body">
                        <div className="profile-body-nav flex items-center justify-center mt-5 mb-7">
                          <a className={"profile-body-nav-item px-7 xs:w-1/2 " + (openTab === 1 ? "active" : "")} onClick={e => {e.preventDefault();setOpenTab(1)}} data-toggle="tab" href="#Redeem" role="tablist">Redeem</a>
                          <a className={"profile-body-nav-item px-7 xs:w-1/2 " + (openTab === 2 ? "active" : "")} onClick={e => {e.preventDefault();setOpenTab(2)}} data-toggle="tab" href="#History" role="tablist">History</a>
                        </div>
                        <div className="tab-content tab-space px-7 pb-4 sm:pb-20">
                          <div className={openTab === 1 ? "block" : "hidden"} id="Redeem">
                            <div className="tab-content-inner grid grid-cols-1 sm:grid-cols-3 gap-0 sm:gap-6 text-center">
                              
                              {redeemPrize?.map((r, index) => (
                                <span>
                                  <button data-index={{ index }} className="xs:flex xs:w-full xs:items-center xs:gap-5" onClick={()=>
                                  // r.prize_id===4?handlePopupRedeemKuota(r.prize_id):
                                  // r.prize_id===3?handlePopupRedeemGopay(r.prize_id):
                                  // r.prize_id===2?handlePopupRedeemBeauty(r.prize_id):""
                                  handlePopupRedeemKuota(r.prize_id,index)
                                  }>
                                    <div className="xs:hidden" dangerouslySetInnerHTML={{ __html: r.prize_name }}></div>
                                    <div className="xs:w-1/2"><img src={r.img_url} /></div>
                                    <div className="xs:flex flex-col"><div className="block sm:hidden text-left xs:mb-1" dangerouslySetInnerHTML={{ __html: r.prize_name }}></div><span>{r.redeem_point} Poin</span></div>
                                  </button>
                                </span>
                              ))}
                              
                            </div>
                          </div>
                          <div className={openTab === 2 ? "block" : "hidden"} id="History">
                            <div className="tab-content-inner tab-content-inner-scroll">
                              <ul>
                                {history?.map((his,i) => (
                                  <li key={i+his.title}>
                                    <span dangerouslySetInnerHTML={{ __html: his.title }}></span>
                                    <span><label>{his.dateStr}</label></span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <SliderContent category="Continue Watching" data={contvid}/>
              </div>
            </section>
          </div>
         <div className="bottom absolute bottom-0 w-full"></div>
        </main>
        <Footer/>
      </div>

      {/* Popup Status */}
      <AnimatePresence>
      {isPopupStatus && (<motion.div className="popup fixed flex items-center justify-center flex-col z-40 top-0 bottom-0 left-0 right-0 overflow-hidden"
        variants={popup}
        initial="hidden"
        animate="show"
        exit="exit"
      >
        {isPopupStatus && (<motion.div className="popup-backdrop absolute top-0 bottom-0 left-0 right-0" onClick={handlePopupStatus}
          variants={popupbackdrop}
          initial="hidden"
          animate="show"
          exit="exit"
        ></motion.div>)}
        {isPopupStatus && (<motion.div className="popup-content popup-content-md relative mx-auto"
          variants={popupcontent}
          initial="hidden"
          animate="show"
          exit="exit"
        >
          <button className="btn-close-login absolute right-0"><img src="https://s.tykcdn.com/assets/pretty_squad/icon/close_btn_pink.svg" onClick={handlePopupStatus} alt="Button close" /></button>
          <div className="h-full overflow-auto p-4 sm:p-9">
            <div className="items-center">
              <div className="profile-btm flex items-center justify-center h-full px-4 pb-5">
                
                {isExpired?
                 <div className="block">
                  <div className="profile-join-sub text-center font-semibold mt-3">Gabung layanan premium disini!</div>
                  <div className="profile-join-btn-wrapper flex justify-center gap-6 mx-auto mt-4">
                  {/* <Isat/> */}
                    {/* <Hti/> */}
                    {/* <Tsel/> */}
                    {console.log(GlobalData.operator)}
                    {GlobalData.operator ==='isat' ?
                      <><Isat/></>
                      : GlobalData.operator === 'hti'?
                      <><Hti/></>
                      : GlobalData.operator='tsel'?
                      <><Tsel/></>
                      : GlobalData.operator === 'all'?
                        <>  <Isat/>  <Hti/> <Tsel/></>
                      :''
                    }
                  </div>
                  <div className="profile-join-detail sm:flex items-center px-5 py-4">
                    <div className="profile-join-detail-sub font-semibold xs:mb-3">Dapatkan<br className="xs:hidden"/> keuntungan :</div>
                    <ul className="sm:ml-5">
                      <li>Nonton premium content sepuasnya</li>
                      <li>Menangkan daily prize!</li>
                      <li>Main lucky draw berhadiah!</li>
                      <li>Kumpulkan poin dan dapatkan grand prize!</li>
                    </ul>
                  </div>
                </div>
                :
                <div className="block">
                  <div className="profile-joined p-4 text-center">
                    <div className="profile-joined-sub font-semibold">Kamu sudah bergabung menjadi anggota premium.</div>
                    <div className="profile-joined-box flex items-center justify-center mx-auto my-4 sm:mt-6 sm:mb-10">Paket premium kamu aktif<br/>hingga {expiredStr} </div>
                    <div className="profile-joined-note">*Pastikan pulsa mencukupi agar status premium kamu berhasil diperbaharui.</div>
                  </div>
                </div>
                }
               
                
              </div>
            </div>
          </div>
        </motion.div>)}
      </motion.div>)}
      </AnimatePresence>
      {/* End Popup Status */}

      {/* Popup Poin */}
      <AnimatePresence>
      {isPopupPoin && (<motion.div className="popup fixed flex items-center justify-center flex-col z-40 top-0 bottom-0 left-0 right-0 overflow-hidden"
        variants={popup}
        initial="hidden"
        animate="show"
        exit="exit"
      >
        {isPopupPoin && (<motion.div className="popup-backdrop absolute top-0 bottom-0 left-0 right-0" onClick={handlePopupPoin}
          variants={popupbackdrop}
          initial="hidden"handlePopupPoin
          animate="show"
          exit="exit"
        ></motion.div>)}
        {isPopupPoin && (<motion.div className="popup-content relative mx-auto"
          variants={popupcontent}
          initial="hidden"
          animate="show"
          exit="exit"
        >
          <button className="btn-close-login absolute right-0"><img src="https://s.tykcdn.com/assets/pretty_squad/icon/close_btn_pink.svg" onClick={handlePopupPoin} alt="Button close" /></button>
          <div className="h-full overflow-auto p-3 sm:p-9">
            <div className="items-center">
              <div className="popup-profile-gen popup-poin-inner px-4 sm:px-7 py-3 sm:py-5">
                <h3>PRETTY POIN</h3>
                <ul>
                  <li>Sebagai bentuk apresiasi kami kepada member premium, <b>Pretty Squad</b> menghadirkan Pretty Poin yang bisa didapatkan dan dikumpulkan hanya untuk member premium dari <b>Pretty Squad</b>.</li>
                  <li>Member premium dari <b>Pretty Squad</b> dapat mengumpulkan poin dengan cara:
                    <ul>
                      <li><b>Beauty Quiz</b>. Member premium memiliki kesempatan untuk berpartisipasi dalam Beauty Quiz yang terdapat di homepage. Berikut perhitungan poin yang didapat dari Beauty Quiz:
                        <div className="popup-poin-quiz grid grid-cols-2 gap-x-5 px-5 py-3.5 mt-2 mb-4">
                          <div>Benar 1 = <b>5 poin</b></div>
                          <div>Benar 4 = <b>20 poin</b></div>
                          <div>Benar 2 = <b>10 poin</b></div>
                          <div>Benar 5 = <b>25 poin</b></div>
                          <div>Benar 3 = <b>15 poin</b></div>
                        </div>
                      </li>
                      <li><b>Lucky Wheel</b>. Member premium memiliki kesempatan untuk bermain fitur Lucky Wheel. Berikut daftar hadiah yang bisa didapatkan dari bermain Lucky Wheel:
                        <div className="popup-poin-draw px-5 py-3.5 mt-2 mb-4">
                          <ul className="grid grid-cols-2 gap-x-5">
                            <li>10 poin</li>
                            {GlobalData.operator==='tsel'?<li>Pulsa Rp.5000</li> : <li>Kuota 50mb</li> }
                            <li>50 poin</li>
                            {GlobalData.operator==='tsel'?<li>Pulsa Rp.10.000</li> : <li>Kuota 100mb</li>}
                            <li>100 poin</li>
                            {GlobalData.operator==='tsel'?<li>Pulsa Rp.15.000</li> : <li>Kuota 500mb</li>}
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li>Kamu bisa melihat jumlah poin yang kamu miliki pada halaman <b>Profile</b></li>
                  <li>Jika poin yang terkumpul sudah mencukupi, kamu bisa melakukan penukaran poin. Daftar hadiah dan detailnya cek <a onClick={()=>disiniClick()}><b><i>disini</i></b></a></li>
                  <li><b>Pretty Squad</b> berhak untuk mengubah syarat & ketentuan sewaktu-waktu.</li>
                  <li>Syarat & ketentuan berlaku.</li>
                </ul>
              </div>
            </div>
          </div>
        </motion.div>)}
      </motion.div>)}
      </AnimatePresence>
      {/* End Popup Poin */}

      {/* I */}
      <AnimatePresence>
      {isPopupRedeemKuota && (<motion.div className="popup fixed flex items-center justify-center flex-col z-40 top-0 bottom-0 left-0 right-0 overflow-hidden"
        variants={popup}
        initial="hidden"
        animate="show"
        exit="exit"
      >
        {isPopupRedeemKuota && (<motion.div className="popup-backdrop absolute top-0 bottom-0 left-0 right-0" onClick={()=>handlePopupRedeemKuota(pid)}
          variants={popupbackdrop}
          initial="hidden"
          animate="show"
          exit="exit"
        ></motion.div>)}
        {isPopupRedeemKuota && (<motion.div className="popup-content popup-content-lg relative mx-auto"
          variants={popupcontent}
          initial="hidden"
          animate="show"
          exit="exit"
        >
          <button className="btn-close-login absolute right-0"><img src="https://s.tykcdn.com/assets/pretty_squad/icon/close_btn_pink.svg" onClick={()=>handlePopupRedeemKuota(pid)} alt="Button close" /></button>
          <div className="h-full overflow-auto p-3 sm:p-9">
            <div className="items-center">
              <div className="popup-profile-gen popup-redeem-outer">
                <div className="popup-redeem-inner block sm:flex gap-5 p-4 sm:p-7">
                  <div className="popup-redeem-detail flex justify-between flex-col px-5 py-6">
                    <div className="flex justify-between">
                      <span dangerouslySetInnerHTML={{ __html: redeemPrize[indexPrize].prize_name }}></span>
                      <span>{redeemPrize[indexPrize].redeem_point} Poin</span>
                    </div>
                    <img src={redeemPrize[indexPrize].img_url} alt="Kuota Data" />
                    <button className="w-full" onClick={()=>handlePopupRedeemStatus(redeemPrize[indexPrize].prize_id)}>REDEEM</button>
                  </div>
                  <div className="popup-redeem-text mt-5  sm:mt-0">
                    <h5>TERMS AND CONDITIONS</h5>                    
                    <ul className="mt-1">
                      <li>Hanya member premium dari <b>Pretty Squad</b> yang dapat melakukan redeem poin dari hadiah yang ditampilkan.</li>
                      <li>Kamu bisa melihat daftar hadiah yang bisa kamu redeem pada halaman <b>Profile</b>.</li>
                      <li>Untuk dapat melakukan redeem, kamu harus mengumpulkan poin sesuai dengan jumlah minimal poin dari masing-masing hadiah. Informasi mengenai poin, kamu bisa baca <Link to="/"><b><i>disini</i></b></Link>.</li>
                      <li>Kamu akan mendapat konfirmasi dari pihak CS dalam 3x24 jam jika hadiah sudah berhasil diredeem.</li>
                      <li>Kuota hadiah bersifat terbatas.</li>
                      <li>Pretty Squad berhak untuk mengubah syarat & ketentuan sewaktu-waktu.</li>
                      <li>Syarat & ketentuan berlaku.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>)}
      </motion.div>)}
      </AnimatePresence>

      {/* II */}
      <AnimatePresence>
      {isPopupRedeemGopay && (<motion.div className="popup fixed flex items-center justify-center flex-col z-40 top-0 bottom-0 left-0 right-0 overflow-hidden"
        variants={popup}
        initial="hidden"
        animate="show"
        exit="exit"
      >
        {isPopupRedeemGopay && (<motion.div className="popup-backdrop absolute top-0 bottom-0 left-0 right-0" onClick={()=>handlePopupRedeemGopay(pid)}
          variants={popupbackdrop}
          initial="hidden"
          animate="show"
          exit="exit"
        ></motion.div>)}
        {isPopupRedeemGopay && (<motion.div className="popup-content popup-content-lg relative mx-auto"
          variants={popupcontent}
          initial="hidden"
          animate="show"
          exit="exit"
        >
          <button className="btn-close-login absolute right-0"><img src="https://s.tykcdn.com/assets/pretty_squad/icon/close_btn_pink.svg" onClick={()=>handlePopupRedeemGopay(pid)} alt="Button close" /></button>
          <div className="h-full overflow-auto p-3 sm:p-9">
            <div className="items-center">
              <div className="popup-profile-gen popup-redeem-outer">
                <div className="popup-redeem-inner block sm:flex gap-5 p-4 sm:p-7">
                  <div className="popup-redeem-detail flex justify-between flex-col px-5 py-6">
                    <div className="flex justify-between">
                      <span dangerouslySetInnerHTML={{ __html: redeemPrize[1].prize_name }}></span>
                      <span>{redeemPrize[1].redeem_point} Poin</span>
                    </div>
                    <img src={redeemPrize[1].img_url} alt="Gopay" />
                    <button className="w-full" onClick={()=>handlePopupRedeemStatus(redeemPrize[1].prize_id)}>REDEEM</button>
                  </div>
                  <div className="popup-redeem-text mt-5  sm:mt-0">
                    <h5>TERMS AND CONDITIONS</h5>                    
                    <ul className="mt-1">
                      <li>Hanya member premium dari <b>Pretty Squad</b> yang dapat melakukan redeem poin dari hadiah yang ditampilkan.</li>
                      <li>Kamu bisa melihat daftar hadiah yang bisa kamu redeem pada halaman <b>Profile</b>.</li>
                      <li>Untuk dapat melakukan redeem, kamu harus mengumpulkan poin sesuai dengan jumlah minimal poin dari masing-masing hadiah. Informasi mengenai poin, kamu bisa baca <Link to="/"><b><i>disini</i></b></Link>.</li>
                      <li>Kamu akan mendapat konfirmasi dari pihak CS dalam 3x24 jam jika hadiah sudah berhasil diredeem.</li>
                      <li>Kuota hadiah bersifat terbatas.</li>
                      <li>Pretty Squad berhak untuk mengubah syarat & ketentuan sewaktu-waktu.</li>
                      <li>Syarat & ketentuan berlaku.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>)}
      </motion.div>)}
      </AnimatePresence>

      {/* III */}
      <AnimatePresence>
      {isPopupRedeemBeauty && (<motion.div className="popup fixed flex items-center justify-center flex-col z-40 top-0 bottom-0 left-0 right-0 overflow-hidden"
        variants={popup}
        initial="hidden"
        animate="show"
        exit="exit"
      >
        {isPopupRedeemBeauty && (<motion.div className="popup-backdrop absolute top-0 bottom-0 left-0 right-0" onClick={()=>handlePopupRedeemBeauty(pid)}
          variants={popupbackdrop}
          initial="hidden"
          animate="show"
          exit="exit"
        ></motion.div>)}
        {isPopupRedeemBeauty && (<motion.div className="popup-content popup-content-lg relative mx-auto"
          variants={popupcontent}
          initial="hidden"
          animate="show"
          exit="exit"
        >
          <button className="btn-close-login absolute right-0"><img src="https://s.tykcdn.com/assets/pretty_squad/icon/close_btn_pink.svg" onClick={()=>handlePopupRedeemBeauty(pid)} alt="Button close" /></button>
          <div className="h-full overflow-auto p-3 sm:p-9">
            <div className="items-center">
              <div className="popup-profile-gen popup-redeem-outer">
                <div className="popup-redeem-inner block sm:flex gap-5 p-4 sm:p-7">
                  <div className="popup-redeem-detail flex justify-between flex-col px-5 py-6">
                    <div className="flex justify-between">
                      <span dangerouslySetInnerHTML={{ __html: redeemPrize[2].prize_name }}></span>
                      <span>{redeemPrize[2].redeem_point} Poin</span>
                    </div>
                    <img src={redeemPrize[2].img_url} alt="Korean Beauty" />
                    <button className="w-full" onClick={()=>handlePopupRedeemStatus(redeemPrize[2].prize_id)}>REDEEM</button>
                  </div>
                  <div className="popup-redeem-text mt-5  sm:mt-0">
                    <h5>TERMS AND CONDITIONS</h5>                    
                    <ul className="mt-1">
                      <li>Hanya member premium dari <b>Pretty Squad</b> yang dapat melakukan redeem poin dari hadiah yang ditampilkan.</li>
                      <li>Kamu bisa melihat daftar hadiah yang bisa kamu redeem pada halaman <b>Profile</b>.</li>
                      <li>Untuk dapat melakukan redeem, kamu harus mengumpulkan poin sesuai dengan jumlah minimal poin dari masing-masing hadiah. Informasi mengenai poin, kamu bisa baca <Link to="/"><b><i>disini</i></b></Link>.</li>
                      <li>Kamu akan mendapat konfirmasi dari pihak CS dalam 3x24 jam jika hadiah sudah berhasil diredeem.</li>
                      <li>Kuota hadiah bersifat terbatas.</li>
                      <li>Pretty Squad berhak untuk mengubah syarat & ketentuan sewaktu-waktu.</li>
                      <li>Syarat & ketentuan berlaku.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>)}
      </motion.div>)}
      </AnimatePresence>
     

      {/* Popup Redeem Status */}
      <AnimatePresence>
      {isPopupRedeemStatus && (<motion.div className="popup fixed flex items-center justify-center flex-col z-40 top-0 bottom-0 left-0 right-0 overflow-hidden"
        variants={popup}
        initial="hidden"
        animate="show"
        exit="exit"
      >
        {isPopupRedeemStatus && (<motion.div className="popup-backdrop absolute top-0 bottom-0 left-0 right-0" onClick={()=>handlePopupRedeemStatus(0)}
          variants={popupbackdrop}
          initial="hidden"
          animate="show"
          exit="exit"
        ></motion.div>)}
        {isPopupRedeemStatus && (<motion.div className="popup-content popup-content-md relative mx-auto"
          variants={popupcontent}
          initial="hidden"
          animate="show"
          exit="exit"
        >
          <button className="btn-close-login absolute right-0"><img src="https://s.tykcdn.com/assets/pretty_squad/icon/close_btn_pink.svg" onClick={()=>handlePopupRedeemStatus(0)} alt="Button close" /></button>
          <div className="h-full overflow-auto p-3 sm:p-9">
            <div className="items-center">
              <div className="popup-profile-redeem-status text-center px-6 py-8 sm:p-10">
                <h3>{msgRedeem}</h3>
                <p className="mt-4">{descRedeem}</p>
                <button className="w-full mt-7" onClick={()=>handlePopupRedeemStatus(0)}>OKE</button>
              </div>
            </div>
          </div>
        </motion.div>)}
      </motion.div>)}
      </AnimatePresence>
      {/* End Popup Redeem Status */}

    </div>
  )

}

export default Profile