import React, {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import Draggable from 'react-draggable'
import { useHookstate } from '@hookstate/core'
import { ISLOGIN } from '../App'

export default function FloatingBtn({operator}) {  

  const navigate = useNavigate();
  const [isDragging, setDragging] = useState(false);
  const [isStatus, setStatus] = useState(false);

  const sessid = useHookstate(ISLOGIN)

  const handleClick = () =>{
    // if(sessid.get()!==null){
    //   navigate("/lucky-wheel");
    // }else{
    //   navigate("/");
    // }

    // non login user can entered lucky wheel page
    navigate("/lucky-wheel");
  }


  return (

    // <div className="floating-btn fixed right-0 z-10">
    //   <Link to={sessid.get()!==null?"/lucky-wheel":"/"}>
    //     <img src="https://s.tykcdn.com/assets/pretty_squad/icon/floating_btn.png" alt="" />
    //   </Link>
    // </div>
    <>
     <Draggable className='sm:block hidden'
      axis="y"
      cancel={isStatus === true ? ".floating-btn" : ""}
      onDrag={() => setDragging(true)}
      onStop={() => {
        if(!isDragging) {
          setStatus(true);
          setDragging(true);
          handleClick();
        }
        setDragging(false);
        setStatus(false);
    }}

    >
    <div className="floating-btn fixed right-0 z-20 cursor-pointer">
      <div>
        <img src="https://s.tykcdn.com/assets/pretty_squad/icon/floating_btn.png" alt="Floating Button" />
      </div>
    </div>
    </Draggable>

      <a href='/lucky-wheel' className='block sm:hidden'>
      <div className="floating-btn fixed right-0 z-20 cursor-pointer">
      <div>
        <img src="https://s.tykcdn.com/assets/pretty_squad/icon/floating_btn.png" alt="Floating Button" />
      </div>
      </div>
      </a>
    </>

    
   

  )
}