import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from "./ScrollToTop";


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <App operator='all'/>
    </Router>
    <Router basename='tsel'>
      <ScrollToTop />
      <App operator='tsel' />
    </Router>
    <Router basename='isat'>
      <ScrollToTop />
      <App operator='isat'/>
    </Router>
    <Router basename='hti'>
      <ScrollToTop />
      <App operator='hti'/>
    </Router>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
